import { IPageData } from '../../interfaces/page';
import { IMenu } from '../../interfaces/menu';

export const SET_PAGE_DATA = '[base Page] Set';
export const RESET_PAGE_DATA = '[base Page] Reset';
export const UPDATE_PAGE_DATA = '[base Page] Update';
export const UPDATE_PAGE_MENU_DATA = '[base Page] Update menu';

export interface SetPageDataAction {
    type: typeof SET_PAGE_DATA
    payload: IPageData
}

export interface ResetPageDataAction {
    type: typeof RESET_PAGE_DATA
}

export interface UpdatePageDataAction {
    type: typeof UPDATE_PAGE_DATA
    payload: IPageData
}

export interface UpdatePageMenuDataAction {
    type: typeof UPDATE_PAGE_MENU_DATA
    payload: IMenu
}

export type PageActionsTypes = SetPageDataAction | ResetPageDataAction | UpdatePageDataAction | UpdatePageMenuDataAction
