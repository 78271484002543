import { MutableRefObject } from 'react';

export const scrollToTop = (top: number = 0) => {
    window.scrollTo({behavior: 'smooth', top});
};

export const scrollToRef = (ref: MutableRefObject<HTMLDivElement | null>) => {
    const node = ref.current;
    if (node) {
        const height = Math.max(node.clientHeight, node.offsetHeight, node.scrollHeight);
        const top = node.offsetTop + (height / 2) + 15;
        scrollToTop(top);
    }
};