import { useEffect, useState } from 'react';
import { IMenu } from '../interfaces/menu';
import { updatePageMenuData } from '../redux/page-data/actions';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../interfaces/app-state';

export function useMenuItems() {

    const location = useLocation();
    const dispatch = useDispatch();

    const [route, setRoute] = useState('');

    const menuData = useSelector<IAppState, IMenu[]>((state) => state.pageData.menu ?? []);

    useEffect(() => {

        let currentRoute = location.pathname.split('/')[1];

        if (!currentRoute) return;

        const findHtml = currentRoute.indexOf('.html');

        if (findHtml > -1) {
            currentRoute = currentRoute.substr(0, findHtml);
        }

        if (route !== currentRoute) {
            setRoute(currentRoute);
        }

    }, [route, location]);

    useEffect(() => {

        const currentMenu: IMenu | undefined = menuData.find(menuItem => menuItem.eventKey === route);

        if (currentMenu && !currentMenu.active) {
            dispatch(
                updatePageMenuData({
                    ...currentMenu, active: true
                })
            );
        }

        return () => {
            const currentMenu: IMenu | undefined = menuData.find(menuItem => menuItem.active);
            if (currentMenu !== undefined) {
                dispatch(
                    updatePageMenuData({
                        ...currentMenu, active: false
                    })
                );
            }
        };

    }, [dispatch, route, menuData]);
}