import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Col, Row } from 'react-bootstrap';

export const LoadingWrapper = () => {
    return (
        <section className={'wrapper-loading'}>
            <div className={'wrapper-content'}>
                <div className={'container'}>
                    <div className={'card-parallax'}>
                        <Row>
                            <Col md={4}>
                                <Skeleton height={300}/>
                            </Col>
                            <Col md={8}>
                                <p>
                                    <Skeleton height={28} width={300}/>
                                </p>
                                <Skeleton height={10} width={'95%'} className={'mt-2'}/>
                                <Skeleton height={10} width={'98%'}/>
                                <Skeleton height={10} count={2}/>
                                <Skeleton height={10} width={'35%'}/>
                                <Skeleton height={10} width={'97%'}/>
                                <Skeleton height={10} width={'98%'} count={2}/>
                                <Skeleton height={10} width={'99%'} count={2}/>
                                <Skeleton height={10} width={'95%'}/>
                                <Skeleton height={10} width={'55%'}/>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </section>
    );
};