import React from 'react';
import { Card, Nav } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

type Props = {
    count?: number
}

export const PreloadCardPanel = (props: Props) => {
    const count = props.count ?? 5;
    const values = Array.from(Array(count).keys());

    return (
        <>
            {
                values.map((v) => {

                    let className = 'col-lg col-md-4 col-sm-6';
                    if (v === 2)
                        className = 'col-lg col-md-4 col-sm-6 mt-md-0 mt-sm-3 mt-lg-0';
                    if (v === 3)
                        className = 'col-lg col-md-6 col-sm-6 mt-md-3 mt-sm-3 mt-lg-0';
                    if (v === 4)
                        className = 'col-lg col-md-6 col-sm-12 mt-md-3 mt-sm-3 mt-lg-0';

                    return (
                        <Nav.Item className={className} key={v}>
                            <Nav.Link eventKey={'disabled'} className={'card'} disabled>
                                <Card.Body>
                                    <Skeleton height={'50px'} className={'card-img'}/>
                                    <div className={'card-text'}>
                                        <Skeleton width={'150px'}/>
                                    </div>
                                </Card.Body>
                            </Nav.Link>
                        </Nav.Item>
                    );
                })
            }
        </>
    );
};
