import React, { Suspense } from 'react';
import { Loader } from './components/Loader';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import './assets/css/theme.scss';
import './assets/css/prospekt.scss';
import { useHideLoader } from './hooks/useHideLoader';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import PortfolioPage from './pages/PortfolioPage';
import Layout from './components/Layout';
import { useMenuItems } from './hooks/useMenuItems';
import { usePageData } from './hooks/usePage';
//import ga from 'react-ga';

const App: React.FC = () => {
    library.add(fas, fab);

    //const location = useLocation();

    useHideLoader();
    useMenuItems();
    usePageData({error: false});

    const routes: RouteProps[] = [];

    routes.push({path: '/about', component: AboutPage});
    routes.push({path: '/portfolio/:tab/:ship.html', exact: true, component: PortfolioPage});
    routes.push({path: ['/', '/:url.html', '/:url/:tab.html'], exact: true, component: HomePage});

    /*useEffect(() => {
        ga.send(['pageview', location.pathname])
    }, [location]);*/

    return (
        <Suspense fallback={<Loader/>}>
            <Layout>
                <Switch>
                    {
                        routes.map((value, id) => (
                            <Route {...value} key={id}/>
                        ))
                    }
                    <Route path='*'>
                        <Redirect to={'/'}/>
                    </Route>
                </Switch>
            </Layout>
        </Suspense>
    );
};

export default App;
