import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

import { updatePageData } from '../redux/page-data/actions';

import { IPageData } from '../interfaces/page';

export function usePageData(pageData: IPageData) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updatePageData({...pageData, loaded: true}));
    }, [pageData, dispatch]);
}

export function useFetchPageData<T>(
    url: string,
    initialState: T | null = null,
    callback?: (data: T) => any
): [T | null, ((data: T) => void)] {
    const [data, setData] = useState<T | null>(initialState);
    const dispatch = useDispatch();

    const getData = useCallback(async function () {
        const result = await axios.get(url);
        return result.data;
    }, [url]);

    useEffect(() => {
        getData()
            .then((data) => {

                callback && callback(data);

                setTimeout(() => dispatch(updatePageData({fulFilled: true, loaded: true})), 300);

                setData(data);
            })
            .catch((e) => {
                dispatch(updatePageData({error: true}));
                console.error(e);
            });
    }, [url, dispatch, getData, callback]);

    return [data, setData];
}
