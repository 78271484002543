import React, { useEffect } from 'react';

export const Loader = () => {

    useEffect(() => {
        const loader = document.getElementById('#loader');
        setTimeout(() => {
            if (loader) {
                loader.classList.add('fadeOut');
                setTimeout(() => loader.remove(), 300);
            }
        }, 300);
    }, []);

    return (
        <>
            <div id={'loader'}>
                <div className={'spinner'}/>
            </div>
        </>
    );
};
