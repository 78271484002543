import React, { useEffect } from 'react';
import { Loader } from '../components/Loader';
import HTMLReactParser from 'html-react-parser';
import { Redirect } from 'react-router-dom';
import { ReplaceHtml } from '../utils/ReplaceHtml';
import { IPageData } from '../interfaces/page';
import { useFetchPageData, usePageData } from '../hooks/usePage';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../interfaces/app-state';
import { IAbout } from '../interfaces/about';
import { updatePageData } from '../redux/page-data/actions';

const pageData: IPageData = {
    fulFilled: false
};

const AboutPage: React.FC = () => {

    usePageData(pageData);

    const dispatch = useDispatch();
    const fulFilled = useSelector<IAppState, boolean>((state) => state.pageData.fulFilled ?? false);

    const [about] = useFetchPageData<IAbout>('/api/about');

    useEffect(() => {
        if (about && !about.error) {
            dispatch(
                updatePageData({
                    title: about.title,
                    keywords: about.title,
                    description: about.title
                })
            );
        }
    }, [dispatch, about]);

    if (!fulFilled) {
        return <Loader/>;
    }

    if (!about) {
        return null;
    }

    if (about.error) {
        return <Redirect to={'/'}/>;
    }

    return (
        <>
            {HTMLReactParser(about.text, {
                replace: ReplaceHtml
            })}
        </>
    );
};

export default AboutPage;
