import React from 'react';
import { PreloadCardPanel } from '../PreloadCardPanel';
import { Card, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IAppState } from '../../interfaces/app-state';
import { IMenu } from '../../interfaces/menu';

const CardPanel = () => {

    const fulFilled = useSelector<IAppState, boolean>((state) => state.pageData.fulFilled ?? false);

    const menuData = useSelector<IAppState, IMenu[]>((state) => state.pageData.menu ?? []);

    if (!fulFilled || !menuData.length) {
        return <PreloadCardPanel/>;
    }

    return (
        <>
            {
                menuData.map(menuItem => {

                    return (
                        <Nav.Item className={menuItem.c} key={menuItem.id}>
                            <Nav.Link
                                to={menuItem.url}
                                eventKey={menuItem.eventKey}
                                className={'card'}
                                replace
                                as={Link}>
                                <Card.Body>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className={'card-img'}
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 60 60"
                                        enableBackground="new 0 0 60 60">
                                        <g>
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                fill="#004F9F"
                                                d="M29.2,0l29.2,17.2v4.1L29.1,38.5L0,21.3L29.1,4.2l29.3,17.1v4
		L33.3,40.2l0,0L25,45l4.1,2.4l29.2-17.2v4c-11,6.5-21.8,13-32.8,19.5l3.5,2.1l29.2-17.2v4.1L29.2,60L0,42.8v-4.1l22.1,13l3.6-2.1
		L0,34.3v-4L21.5,43l4.1-2.4L0,25.3v-4v-4.1L29.2,0L29.2,0z"/>
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                fill="#008C3C"
                                                d="M29.1,10.2c-6.1,5.4-6.5,14-3.6,21c-1,0-2,0-3,0
		c-2.6-6.3-9.3-10.4-16.1-7.4c-0.7-0.5-1.6-1-2.5-1.6c5.7-3.5,12.8-2.2,17.2,1.9c-0.6-6.6,2.3-13.3,8-17.4c5.5,3.9,8.6,10.7,8,17.4
		c4.4-4,11.5-5.3,17.2-1.9c-0.9,0.6-1.8,1.1-2.5,1.6c-6.8-3-13.5,1.2-16.1,7.4c-1,0-2,0-3,0C35.6,24,35.1,15.5,29.1,10.2L29.1,10.2z
		"/>
                                        </g>
                                    </svg>
                                    <div className={'card-text'}>
                                        {menuItem.title}
                                    </div>
                                </Card.Body>
                            </Nav.Link>
                        </Nav.Item>
                    );
                })
            }
        </>
    );
};

export default CardPanel;
