import axios, { AxiosRequestConfig } from 'axios';
import i18next from 'i18next';

axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        return {
            ...config,
            ...{baseURL: 'https://nibulon.com'},
            ...{headers: {'Accept-Language': i18next.language}}
        };
    },
    error => Promise.reject(error)
);
