import { PageActionsTypes, RESET_PAGE_DATA, SET_PAGE_DATA, UPDATE_PAGE_DATA, UPDATE_PAGE_MENU_DATA } from './types';
import { IPageData } from '../../interfaces/page';

const initialState: IPageData = {
    title: 'НІБУЛОН',
    keywords: 'НІБУЛОН',
    description: 'НІБУЛОН',
    fulFilled: true,
    error: false,
    menu: []
};

export const pageDataReducer = (state: IPageData = initialState, action: PageActionsTypes) => {
    switch (action.type) {
        case SET_PAGE_DATA:
            return {...action.payload, fullFilled: true};
        case UPDATE_PAGE_DATA:
            return {...state, ...action.payload};
        case UPDATE_PAGE_MENU_DATA:
            const menu = state.menu ?? [];
            const updateMenu = {
                menu: menu.map(el => el.eventKey !== action.payload.eventKey ? {
                    ...el,
                    active: false
                } : action.payload)
            };
            return {...state, ...updateMenu};
        case RESET_PAGE_DATA:
            return {...initialState};
        default:
            return {...state};
    }
};
