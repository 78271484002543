import React from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IAppState } from '../interfaces/app-state';
import { IPageData } from '../interfaces/page';
import { Alert, Container } from 'react-bootstrap';

type Props = {
    children: React.ReactNode
}

const Layout = ({children}: Props) => {

    const {i18n} = useTranslation();

    const pageData = useSelector<IAppState, IPageData>((state) => state.pageData);

    if (pageData.error) {
        return (
            <>
                <Helmet htmlAttributes={{lang: i18n.language}}>
                    <title>{pageData.title}</title>
                    <meta name={'keywords'} content={pageData.keywords}/>
                    <meta name={'description'} content={pageData.description}/>
                </Helmet>
                <Header/>
                <Container style={{marginTop: '6rem', marginBottom: '3rem'}}>
                    <Alert variant={'danger'}>
                        Технічна помилка
                    </Alert>
                </Container>
                <Footer/>
            </>
        );
    }

    return (
        <>
            <Helmet htmlAttributes={{lang: i18n.language}}>
                <title>{pageData.title}</title>
                <meta name={'keywords'} content={pageData.keywords}/>
                <meta name={'description'} content={pageData.description}/>
            </Helmet>
            <Header/>
            {children}
            <Footer/>
        </>
    );
};

export default Layout;
