import React, { Suspense } from 'react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import './Api';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { history, store } from './redux/store';
import { Loader } from './components/Loader';
import ReactDOM from 'react-dom';
//import ga from 'react-ga';

//ga.initialize('UA-46304960-1', {gaOptions: {clientId: '234061522'}});

const reactNode = (
    <React.StrictMode>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Suspense fallback={<Loader/>}>
                    <App/>
                </Suspense>
            </ConnectedRouter>
        </Provider>
    </React.StrictMode>
);

//ReactDOM.render(reactNode, document.getElementById('root'));

const rootElement = document.getElementById('root');

if (rootElement!?.hasChildNodes()) {
    ReactDOM.hydrate(reactNode, rootElement);
} else {
    ReactDOM.render(reactNode, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
