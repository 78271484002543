import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { ITabs } from '../../interfaces/page';

type Props = {
    item: ITabs
    child?: boolean
}
const RenderNavItem = ({item, child}: Props) => {

    const navItem = (item: ITabs, bottom?: boolean) => (
        <Nav.Item
            key={item.id}
            className={child ? 'ml-4' : ''}>
            <Nav.Link
                to={item.url}
                eventKey={item.eventKey}
                as={Link}>
                <FontAwesomeIcon
                    icon={bottom ? 'chevron-circle-down' : 'chevron-circle-right'}
                    className={'mr-3'}/>
                {item.title}
            </Nav.Link>
        </Nav.Item>
    );

    if (item.type === 'tree' && item.child && item.child.length > 0) {
        return (
            <Fragment key={item.id}>
                {navItem(item, true)}
                {item.child.map(value => <RenderNavItem child item={value} key={value.id}/>)}
            </Fragment>
        );
    }

    return navItem(item);
};

export default RenderNavItem;
