import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';

import { connectRouter } from 'connected-react-router';
import { createHashHistory } from 'history';
import { pageDataReducer } from './page-data/reducer';
import { settingsReducer } from './settings/reducer';

export const history = createHashHistory();

const rootReducer = combineReducers({
    pageData: pageDataReducer,
    settings: settingsReducer,
    router: connectRouter(history)
});

export type AppState = ReturnType<typeof rootReducer>;

export const store = createStore(rootReducer, applyMiddleware(thunk));
