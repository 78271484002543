import React, { useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink, Redirect, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import HTMLReactParser, { DomElement } from 'html-react-parser';
import Skeleton from 'react-loading-skeleton';
import LazyLoad, { forceCheck } from 'react-lazyload';
import Slider, { Settings } from 'react-slick';
import { useDispatch, useSelector } from 'react-redux';
import { IPageData } from '../interfaces/page';
import { IPortfolio } from '../interfaces/portfolio';
import { IAppState } from '../interfaces/app-state';
import { Loader } from '../components/Loader';
import axios from 'axios';
import { updatePageData } from '../redux/page-data/actions';
import { usePageData } from '../hooks/usePage';

type PortfolioParams = {
    ship: string
}


const pageData: IPageData = {
    fulFilled: false
};

const PortfolioPage: React.FC = () => {

    usePageData(pageData);

    const dispatch = useDispatch();
    const fulFilled = useSelector<IAppState, boolean>((state) => state.pageData.fulFilled ?? false);

    const params = useParams<PortfolioParams>();

    const [portfolio, setPortfolio] = useState<IPortfolio | null>(null);
    const [refSliderPhoto, setRefSliderPhoto] = useState<Slider | null>(null);
    const [refSliderIndicators, setRefSliderIndicators] = useState<Slider | null>(null);


    useEffect(() => {

        dispatch(updatePageData({fulFilled: false}));

        async function fetchPortfolio(): Promise<IPortfolio | null> {
            const result = await axios.get<IPortfolio>(`/api/portfolio?id=${params.ship}`);
            return result.data;
        }

        fetchPortfolio().then((data) => {
            setTimeout(() => dispatch(updatePageData({fulFilled: true})), 300);
            setPortfolio(data);
        }).catch((e) => {
            dispatch(updatePageData({error: true}));
            console.error(e);
        });

    }, [dispatch, params.ship]);

    useEffect(() => {
        if (portfolio && !portfolio.error) {
            dispatch(
                updatePageData({
                    title: portfolio.title,
                    keywords: portfolio.title,
                    description: portfolio.title
                })
            );
        }
    }, [dispatch, portfolio]);

    const settingsSliderIndicators: Settings = {
        infinite: false,
        asNavFor: refSliderPhoto ?? undefined,
        focusOnSelect: true,
        arrows: false,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        variableWidth: true,
        onInit() {
            onForceCheck();
        },
        onReInit() {
            onForceCheck();
        },
        afterChange() {
            onForceCheck();
        }
    };

    const onForceCheck = () => setTimeout(forceCheck, 5e2);

    const settingsSliderPhoto: Settings = {
        asNavFor: refSliderIndicators ?? undefined,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        dots: false,
        fade: true
    };

    if (!fulFilled) {
        return <Loader/>;
    }

    if (!portfolio) {
        return null;
    }

    if (portfolio.error) {
        return <Redirect to={'/'}/>;
    }

    return (
        <>
            <section className={'wrapper-portfolio'}>
                <Nav className="justify-content-center container">
                    {
                        portfolio.navbar && portfolio.navbar.map((item) => {
                            return (
                                <div className={'nav-item'} key={item.id}>
                                    <NavLink
                                        to={item.url}
                                        className={'nav-link'}
                                        isActive={() => item.selected}>
                                        <FontAwesomeIcon
                                            icon={(`${item.selected ? 'minus' : 'plus'}-circle`) as IconProp}
                                            height={'10px'}/>
                                        {item.title}
                                    </NavLink>
                                </div>
                            );
                        })
                    }
                </Nav>
                {
                    HTMLReactParser(portfolio.text, {
                        replace: (domNode: DomElement) => {
                            if (domNode.attribs && domNode.attribs.id === 'gallery') {
                                return (
                                    <div className={'photo-gallery'}>
                                        <div className={'photo-content'}>
                                            <Slider {...settingsSliderPhoto} ref={setRefSliderPhoto}>
                                                {
                                                    portfolio.photos.map((image) => (
                                                        <LazyLoad
                                                            classNamePrefix={`lazyload`}
                                                            placeholder={<Skeleton className={'h-400'}/>}
                                                            key={image.id}>
                                                            <img
                                                                src={image.url}
                                                                alt={portfolio.title}
                                                            />
                                                        </LazyLoad>
                                                    ))
                                                }
                                            </Slider>
                                        </div>
                                        <div className={'photo-indicators'}>
                                            <Slider {...settingsSliderIndicators} ref={setRefSliderIndicators}>
                                                {
                                                    portfolio.photos.map((image) => (
                                                        <LazyLoad
                                                            placeholder={<Skeleton/>}
                                                            key={image.id}>
                                                            <img
                                                                className="img-fluid"
                                                                src={image.url}
                                                                alt={portfolio.title}
                                                            />
                                                        </LazyLoad>
                                                    ))
                                                }
                                            </Slider>
                                        </div>
                                    </div>
                                );
                            }
                        }
                    })
                }
            </section>
        </>
    );
};

export default PortfolioPage;
