import {
    RESET_PAGE_DATA,
    ResetPageDataAction,
    SET_PAGE_DATA,
    SetPageDataAction,
    UPDATE_PAGE_DATA,
    UPDATE_PAGE_MENU_DATA,
    UpdatePageDataAction,
    UpdatePageMenuDataAction
} from './types';

import { IPageData } from '../../interfaces/page';
import { IMenu } from '../../interfaces/menu';

export const setPageData = (data: IPageData): SetPageDataAction => ({
    type: SET_PAGE_DATA,
    payload: data
});

export const updatePageData = (data: IPageData): UpdatePageDataAction => ({
    type: UPDATE_PAGE_DATA,
    payload: data
});

export const updatePageMenuData = (data: IMenu): UpdatePageMenuDataAction => ({
    type: UPDATE_PAGE_MENU_DATA,
    payload: data
});

export const resetPageData = (): ResetPageDataAction => ({
    type: RESET_PAGE_DATA
});
