import { DomElement, domToReact } from 'html-react-parser';
import { Link } from 'react-router-dom';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findDomElement } from './findDomElement';
import Skeleton from 'react-loading-skeleton';
import { Card } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import React from 'react';

export const ReplaceHtml = (domNode: DomElement) => {

    if (domNode.attribs) {

        if (domNode.attribs['data-react'] === 'link') {
            const dataHref = domNode.attribs['href'];
            const dataClass = domNode.attribs.class;

            if (dataHref && domNode.children) {
                return (
                    <Link
                        to={dataHref}
                        className={dataClass}>
                        {domToReact(domNode.children, {replace: ReplaceHtml})}
                    </Link>
                );
            }

            return <></>;
        }

        if (domNode.attribs['data-react'] === 'fontawesome-icon') {
            const dataIconPrefix = domNode.attribs['data-prefix'] as IconPrefix;
            const dataIconName = domNode.attribs['data-name'] as IconName;
            const dataClass = domNode.attribs['data-class'];

            if (dataIconPrefix && dataIconName) {
                return <FontAwesomeIcon icon={[dataIconPrefix, dataIconName]} className={dataClass}/>;
            }

            return <></>;
        }

        if (domNode.attribs.class && domNode.attribs.class.indexOf('lazyload-wrapper') !== -1) {
            const findNodeImg = findDomElement('img', domNode);
            const className = domNode.attribs.class.replace('lazyload-wrapper', '');
            let cardImg = <Skeleton className={className}/>;

            if (findNodeImg && findNodeImg.attribs && findNodeImg.attribs.src) {
                cardImg = <Card.Img src={findNodeImg.attribs.src} className={className}/>;
            }

            return (
                <LazyLoad
                    classNamePrefix={`${className} lazyload`}
                    placeholder={<Skeleton className={className}/>}>
                    <Card>{cardImg}</Card>
                </LazyLoad>
            );
        }
    }
};
