import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IAppState } from '../../interfaces/app-state';
import { Navbar } from 'react-bootstrap';
import logoEn from '../../assets/images/logo/logo_en.svg';
import logoUk from '../../assets/images/logo/logo_ua.svg';

export const Logo = () => {

    const {t, i18n} = useTranslation();
    const titlePage = useSelector<IAppState, string | undefined>((state) => state.pageData.title);

    return (
        <Navbar.Brand href={'/'}>
            <img
                src={i18n.language === 'en' ? logoEn : logoUk}
                alt={titlePage ?? t('НІБУЛОН')}
                style={{width: '150px'}}/>
        </Navbar.Brand>
    );
};