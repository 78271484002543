import { Loading } from './loading';
import React from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from '../../interfaces/app-state';
import { IMenu } from '../../interfaces/menu';
import { NavLink, NavLinkProps } from 'react-router-dom';


export const MenuItems = () => {

    const menuData = useSelector<IAppState, IMenu[]>((state) => state.pageData.menu ?? []);
    const loaded = useSelector<IAppState, boolean>((state) => state.pageData.loaded ?? false);

    if (!loaded || !menuData.length) {
        return <Loading/>;
    }

    return (
        <>
            {
                menuData.map(menuItem => {
                    const props = {
                        role: 'button',
                        to: menuItem.url,
                        key: menuItem.id,
                        className: 'nav-link',
                        isActive: () => menuItem.active
                    } as NavLinkProps;

                    return (
                        <NavLink {...props}>
                            {menuItem.title}
                        </NavLink>
                    );
                })
            }
        </>
    );
};