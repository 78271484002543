import i18next from 'i18next';
import I18NextHttpBackend from 'i18next-http-backend';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

export const supportedLngs = ['uk', 'en'];

let currentLng = localStorage.getItem('i18nextLng') ?? 'uk';

if (supportedLngs.indexOf(currentLng) === -1) {
    currentLng = 'uk';
}

i18next
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(I18nextBrowserLanguageDetector)
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(I18NextHttpBackend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: currentLng,
        fallbackLng: 'uk',
        supportedLngs: supportedLngs,
        debug: process.env.NODE_ENV === 'development',
        load: 'languageOnly',
        ns: 'messages',
        defaultNS: 'messages',
        backend: {
            //loadPath: '/api/language/{{ns}}/{{lng}}'
            loadPath: 'https://nibulon.com/api/language?lng={{lng}}'
        },
        detection: {
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            lookupSessionStorage: 'i18nextLng'
        },
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        }
    }).then();
