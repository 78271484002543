import React from 'react';
import HTMLReactParser from 'html-react-parser';
import { ReplaceHtml } from '../../utils/ReplaceHtml';
import { IWrapper } from '../../interfaces/page';

type Props = {
    wrapper: IWrapper
}

const RenderStaticWrapper = ({wrapper}: Props) => (
    <section key={wrapper.id} className={`wrapper-${wrapper.eventKey}`}>
        {
            HTMLReactParser(wrapper.text, {
                replace: ReplaceHtml
            })
        }
    </section>
);

export default RenderStaticWrapper;
