import React, { useEffect, useRef, useState } from 'react';
import { Carousel, Col, Container, Nav, Row, Tab } from 'react-bootstrap';
import carousel_1 from '../assets/images/carousel_1.jpg';
import carousel_2 from '../assets/images/carousel_2.jpg';
import carousel_3 from '../assets/images/carousel_3.jpg';
import { useTranslation } from 'react-i18next';
import { SelectCallback } from 'react-bootstrap/helpers';
import { forceCheck } from 'react-lazyload';
import HTMLReactParser from 'html-react-parser';
import { useHistory, useParams } from 'react-router-dom';
import CardPanel from '../components/Home/CardPanel';
import { usePageData } from '../hooks/usePage';
import { IPageData, ITabs, IWrapper } from '../interfaces/page';
import { useDispatch, useSelector } from 'react-redux';
import { updatePageData } from '../redux/page-data/actions';
import RenderTabContainer from '../components/Home/RenderTabContainer';
import RenderNavItem from '../components/Home/RenderNavItem';
import RenderStaticWrapper from '../components/Home/RenderStaticWrapper';
import { IAppState } from '../interfaces/app-state';
import { scrollToRef } from '../utils/scrollToTop';
import axios from 'axios';
import { LoadingWrapper } from '../components/Home/LoadingWrapper';
import { Loader } from '../components/Loader';

const pageData: IPageData = {
    fulFilled: false,
    tabLoaded: false
};

type Props = {
    url: string
    tab: string
};

const HomePage: React.FC = () => {
    usePageData(pageData);

    const {t, i18n} = useTranslation();

    const refObjectSlider = useRef<HTMLDivElement>(null);
    const refObjectCard = useRef<HTMLDivElement>(null);

    const params = useParams<Props>();

    const [cardTab, setCardTab] = useState('');
    const [tabTab, setTabTab] = useState('');

    const titlePage = useSelector<IAppState, string>((state) => state.pageData.title ?? '');
    const fulFilled = useSelector<IAppState, boolean>((state) => state.pageData.fulFilled ?? false);
    const tabLoaded = useSelector<IAppState, boolean>((state) => state.pageData.tabLoaded ?? false);

    const history = useHistory();

    const dispatch = useDispatch();

    const [wrappers, setWrappers] = useState<IWrapper[]>([]);
    const [tabs, setTabs] = useState<ITabs[]>([]);

    const onSelectTabHandle: SelectCallback = (eventKey) => {
        eventKey && setTabTab(eventKey);
        dispatch(updatePageData({tabLoaded: false}));

        scrollToRef(refObjectCard);
        setTimeout(() => {
            dispatch(updatePageData({tabLoaded: true}));
            forceCheck();
        }, 1e3);
    };

    const onSelectCardHandle: SelectCallback = (eventKey) => {
        eventKey && setCardTab(eventKey);
    };

    useEffect(() => {
        dispatch(updatePageData({
            fulFilled: false,
            title: t('НІБУЛОН'),
            keywords: t('НІБУЛОН'),
            description: t('НІБУЛОН')
        }));

        setCardTab('');
        setTabTab('');

        async function fetchWrapper(): Promise<IWrapper[]> {
            const result = await axios.get<IWrapper[]>(`/api/statics`);
            return result.data;
        }

        async function fetchTabs(): Promise<ITabs[]> {
            const result = await axios.get<ITabs[]>(`/api/tabs`);
            return result.data;
        }

        fetchTabs().then((data) => {
            setTabs(data);
            return fetchWrapper();
        }).then((data) => {
            setWrappers(data);
        }).finally(() => {
            dispatch(updatePageData({fulFilled: true}));
        }).catch((e) => {
            dispatch(updatePageData({error: true}));
            console.error(e);
        });

    }, [dispatch, i18n.language, t]);

    useEffect(() => {
        if (params.url) {
            setCardTab(params.url);
            params.tab && setTabTab(params.tab);
        }
    }, [params]);

    useEffect(() => {
        const node = refObjectSlider.current;
        if (node && node.classList) {
            if (!node.classList.contains('animate-init')) {
                node.classList.add('animate-init');
            }
            if (fulFilled) {
                if (!node.classList.contains('animate-start')) {
                    node.classList.add('animate-start');
                }
                setTimeout(() => {
                    if (!node.classList.contains('animated-end')) {
                        node.classList.add('animated-end');
                    }
                }, 2e3);
            } else {
                if (node.classList.contains('animate-start')) {
                    node.classList.remove('animate-start');
                }
                if (node.classList.contains('animated-end')) {
                    node.classList.remove('animated-end');
                }
            }
        }
    }, [fulFilled]);

    useEffect(() => {
        if (fulFilled && cardTab && tabs) {

            const card = tabs.find(item => item.eventKey === cardTab);

            if (card) {
                let title = `${card.title}`;

                scrollToRef(refObjectCard);
                setTimeout(() => {
                    dispatch(updatePageData({tabLoaded: true}));
                    forceCheck();
                }, 1e3);

                if (tabTab) {
                    const tab = card.child.find(tabChild => tabChild.eventKey === tabTab);
                    if (tab && title !== tab.title) {
                        title += ` - ${tab.title}`;
                    }
                }

                dispatch(
                    updatePageData({
                        title,
                        keywords: title,
                        description: title
                    })
                );

                setWrappers([]);
            } else {
                history.push('/');
            }
        }

    }, [cardTab, dispatch, fulFilled, history, setWrappers, tabTab, tabs]);

    if (!fulFilled) {
        return <Loader/>;
    }

    return (
        <>
            <section className={'wrapper-carousel'}>
                <div className={'carousel-animated'} ref={refObjectSlider}>
                    <Carousel
                        interval={2500}
                        indicators={false}
                        controls={false}
                        pause={false}>
                        <Carousel.Item>
                            <div className={'slider-container'}>
                                <div className={'slider-cover'}>
                                    <img src={carousel_1} alt={titlePage}/>
                                    <div className={'overlay'}/>
                                    <div className={'overlay-1'}/>
                                </div>
                                <div className={'slider-text'}>
                                    <div className="text">
                                        <h1>{t('mainPageTitle')}</h1>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className={'slider-container'}>
                                <div className={'slider-cover'}>
                                    <img src={carousel_2} alt={titlePage}/>
                                    <div className={'overlay'}/>
                                    <div className={'overlay-1'}/>
                                </div>
                                <div className={'slider-text'}>
                                    <div className="text">
                                        <h1>{t('mainPageTitle')}</h1>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className={'slider-container'}>
                                <div className={'slider-cover'}>
                                    <img src={carousel_3} alt={titlePage}/>
                                    <div className={'overlay'}/>
                                    <div className={'overlay-1'}/>
                                </div>
                                <div className={'slider-text'}>
                                    <div className="text">
                                        <h1>{t('mainPageTitle')}</h1>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </section>
            <section className={'wrapper-container'}>
                <Container className={'pb-3'}>
                    <Tab.Container
                        id={'tabs-panel'}
                        activeKey={cardTab}
                        onSelect={onSelectCardHandle}>
                        <Nav
                            id={'card-panel'}
                            variant="pills"
                            className="card-panel row-cols-lg-5"
                            aria-orientation="vertical"
                            ref={refObjectCard}>
                            <CardPanel/>
                        </Nav>
                        <Tab.Content
                            className={`tabs-container${cardTab === '' ? ' d-none' : ''}`}>
                            {
                                tabs.map(
                                    tab => (
                                        <Tab.Pane
                                            eventKey={tab.eventKey}
                                            className={'tab-container'}
                                            key={tab.id}>
                                            {
                                                !tab.child.length ? (
                                                    <Tab.Content>
                                                        {HTMLReactParser(tab.text)}
                                                    </Tab.Content>
                                                ) : (
                                                    <Tab.Container
                                                        id={`tab-content-${tab.eventKey}`}
                                                        activeKey={tabTab}
                                                        onSelect={onSelectTabHandle}>
                                                        <Row>
                                                            <Col
                                                                lg={3}
                                                                md={4}
                                                                className={'mb-3 mb-md-0'}>
                                                                <Nav
                                                                    variant="pills"
                                                                    className="flex-column"
                                                                    key={tab.id}
                                                                    aria-orientation="vertical">
                                                                    {
                                                                        tab.child.map(item => <RenderNavItem
                                                                            item={item} key={item.id}/>)
                                                                    }
                                                                </Nav>
                                                            </Col>
                                                            <Col
                                                                lg={9}
                                                                md={8}>
                                                                <Tab.Content
                                                                    className={`tab-container${!tabLoaded ? ' is-loading' : ''}`}>
                                                                    {
                                                                        tab.child.map(item => <RenderTabContainer
                                                                            item={item} key={item.id}/>)
                                                                    }
                                                                </Tab.Content>
                                                            </Col>
                                                        </Row>
                                                    </Tab.Container>
                                                )
                                            }
                                        </Tab.Pane>
                                    )
                                )
                            }
                        </Tab.Content>
                    </Tab.Container>
                </Container>
            </section>
            {
                fulFilled ?
                    wrappers.map(wrapper => <RenderStaticWrapper wrapper={wrapper} key={wrapper.id}/>) :
                    <LoadingWrapper/>
            }
        </>
    );
};

export default HomePage;
