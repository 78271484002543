import React from 'react';
import Skeleton from 'react-loading-skeleton';

type Props = {
    count?: number
}

export const Loading = (props: Props) => {

    const count = props.count ?? 5;
    const values = Array.from(Array(count).keys());

    return (
        <>
            {
                values.map((v) => <Skeleton height={'10px'} width={'150px'} className={'nav-link mt-3 mr-3'} key={v}/>)
            }
        </>
    );
};