import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import className from '../utils/class-names';
import { scrollToTop } from '../utils/scrollToTop';
import { Logo } from './navbar';
import { supportedLngs } from '../i18n';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { updatePageData } from '../redux/page-data/actions';
import { MenuItems } from './navbar/menu-items';
import { IAppState } from '../interfaces/app-state';
import { toggleSidebar } from '../redux/settings/actions';
import { IMenu } from '../interfaces/menu';

export const Header = () => {

    const dispatch = useDispatch();

    const {i18n} = useTranslation();

    const refObjectPanel = useRef<HTMLDivElement>(null);

    const navbarOpened = useSelector<IAppState, boolean>((state) => state.settings.navbarOpened);

    const [navClassNames, setNavClassNames] = useState({
        'nb-navbar': true,
        'animate__animated': true,
        scrolled: false,
        awake: false,
        sleep: false
    });

    const onSidebarToggle = () => dispatch(toggleSidebar());

    const animationHandle = useCallback(() => {
        const nodePanel = refObjectPanel.current;
        if (nodePanel && nodePanel.classList.contains('animate')) {
            if (nodePanel.classList.contains('fadeOutDown'))
                nodePanel.classList.add('navbar-hide');
            nodePanel.classList.remove('animate', 'fadeOutDown', 'fadeInUp');
        }
    }, []);

    const scrollHandle = useCallback(() => {

        const scrollTop = Math.max(document.body.scrollTop, window.pageYOffset, document.documentElement.scrollTop);

        if (scrollTop > 150 && !navClassNames.scrolled) {
            setNavClassNames(prevState => {
                return {...prevState, scrolled: true};
            });
        }

        if (scrollTop < 150 && navClassNames.scrolled) {
            setNavClassNames(prevState => {
                return {...prevState, scrolled: false, sleep: false};
            });
        }

        if (scrollTop > 350 && !navClassNames.awake) {
            setNavClassNames(prevState => {
                return {...prevState, awake: true, sleep: false};
            });
        }

        if (scrollTop < 350 && navClassNames.awake) {
            setNavClassNames(prevState => {
                return {...prevState, awake: false, sleep: true};
            });
        }

        const nodeCardPanel: HTMLElement | null = document.getElementById('card-panel');

        if (nodeCardPanel) {
            const nodeCardPanelTop =
                Math.max(nodeCardPanel.offsetTop, nodeCardPanel.scrollTop, 0);

            const nodePanel = refObjectPanel.current;

            if (nodePanel) {
                if (scrollTop >= nodeCardPanelTop) {
                    if (nodePanel.classList.contains('navbar-hide')) {
                        nodePanel.classList.remove('navbar-hide');
                        nodePanel.classList.add('animate', 'fadeInUp');
                    }
                } else {
                    if (!nodePanel.classList.contains('navbar-hide')) {
                        nodePanel.classList.add('animate', 'fadeOutDown');
                    }
                }
            }
        }

    }, [navClassNames.awake, navClassNames.scrolled]);

    const changeLanguageHandler = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        const node = e.target as HTMLAnchorElement;
        const lang = node.lang ?? 'uk';

        if (lang === i18n.language)
            return;

        scrollToTop();

        dispatch(updatePageData({menu: [], loaded: false}));
        await i18n.changeLanguage(lang);
        setTimeout(() => dispatch(updatePageData({loaded: true})), 300);
    };

    useEffect(() => {
        async function fetchMenuData(): Promise<IMenu[]> {
            const result = await axios.get<IMenu[]>('/api/cards');
            return result.data;
        }

        fetchMenuData().then((data) => {
            dispatch(updatePageData({
                menu: data
            }));
        }).catch((e) => {
            dispatch(updatePageData({error: true}));
            console.error(e);
        });
    }, [dispatch, i18n.language]);

    useEffect(() => {

        window.addEventListener('scroll', scrollHandle);

        const nodePanel = refObjectPanel.current;
        nodePanel && nodePanel.addEventListener('animationend', animationHandle);

        return () => {
            window.removeEventListener('scroll', scrollHandle);
            nodePanel && nodePanel.addEventListener('animationend', animationHandle);
        };
    }, [animationHandle, navClassNames, scrollHandle]);

    return (
        <>
            <Navbar expand={'lg'} className={className(navClassNames)} onToggle={onSidebarToggle}>
                <Container>
                    <Logo/>
                    <Navbar.Toggle
                        aria-controls="basic-navbar-nav"
                        aria-expanded={navbarOpened}>
                    <span className={'u-hamburger__box'}>
                        <span className={'u-hamburger__inner'}/>
                    </span>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav
                            className="navbar-panel navbar-hide"
                            ref={refObjectPanel}>
                            <MenuItems/>
                        </Nav>
                        <div className="btns-language ml-auto mb-3 mb-lg-0">
                            {
                                supportedLngs.map((value: string, key: number) =>
                                    (
                                        <Link
                                            key={key}
                                            className={`text-decoration-none${i18n.language === value ? ' active' : ''}`}
                                            lang={value}
                                            onClick={changeLanguageHandler}
                                            to={`/`}>
                                            {value.toLocaleUpperCase()}
                                        </Link>
                                    )
                                )
                            }
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};
