import { DomElement } from 'html-react-parser';

export function findDomElement(id: string, currentNode: DomElement): DomElement | undefined {

    if (id === currentNode.name) {
        return currentNode;
    } else {

        if (currentNode.children) {
            for (let i = 0; i < currentNode.children.length; i += 1) {
                const currentChild = currentNode.children[i];

                const result = findDomElement(id, currentChild);

                if (result !== undefined) {
                    return result;
                }
            }
        }

        return undefined;
    }
}
