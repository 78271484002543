import React from 'react';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { MenuItems } from './navbar/menu-items';

export const Footer = () => {

    const {t} = useTranslation();

    return (
        <footer className={'footer test-4'}>
            <Container>
                <Row>
                    <Col lg={6}>
                        <h3 className={'footer-title text-uppercase mb-4'}>
                            {t('Зв’яжіться з нами')}
                        </h3>
                        <div className={'contact-info'}>
                            <Nav className={'row-cols-sm-2 row-cols-1'}>
                                <Nav.Link as={'div'}>
                                    <h4 className={'mb-2'}>
                                        <FontAwesomeIcon icon={'phone'} className={'mr-2'}/>
                                        {t('Телефон')}
                                    </h4>
                                    <a href="tel:+380512675754"><p>+38 (0512) 67-57-54</p></a>
                                </Nav.Link>
                                <Nav.Link as={'div'}>
                                    <h4 className={'mb-2'}>
                                        <FontAwesomeIcon icon={'envelope-open'} className={'mr-2'}/>
                                        {t('Email')}
                                    </h4>
                                    <a href="mailto:secretar-ssz@nibulon.com.ua">
                                        <p>secretar-ssz@nibulon.com.ua</p>
                                    </a>
                                </Nav.Link>
                                <Nav.Link
                                    href={'#'}
                                    target={'_blank'}>
                                    <h4 className="mb-2">
                                        <FontAwesomeIcon icon={'map-marked'} className={'mr-2'}/>
                                        {t('Адреса')}
                                    </h4>
                                    <p>{t('footerLocation')}</p>
                                </Nav.Link>
                                <Nav.Link href={'https://www.facebook.com/nibulonltd'} target={'_blank'}>
                                    <h4 className="mb-2">
                                        <FontAwesomeIcon icon={['fab', 'facebook-f']} className={'mr-2'}/>
                                        Facebook
                                    </h4>
                                </Nav.Link>
                            </Nav>
                        </div>
                    </Col>
                    <Col lg={6} className={'mt-lg-0 mt-sm-5 mt-4'}>
                        <h3 className="footer-title text-uppercase mb-4">{t('Швидкі посилання')}</h3>
                        <Nav className={'row-cols-sm-2 row-cols-1'}>
                            <MenuItems/>
                        </Nav>
                    </Col>
                </Row>
                <div className="d-flex justify-content-center mt-3">
                    <p className={'text-center text-white'}>© {new Date().getFullYear()} ТОВ СП НІБУЛОН</p>
                </div>
            </Container>
        </footer>
    );
};
