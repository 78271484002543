import HTMLReactParser from 'html-react-parser';
import { ReplaceHtml } from '../../utils/ReplaceHtml';
import { Card, Col, Row, Tab } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { ITabs } from '../../interfaces/page';

type Props = {
    item: ITabs
}

const RenderTabContainer = ({item}: Props) => {

    const tabPane = (item: ITabs) => (
        <Tab.Pane
            key={item.id}
            eventKey={item.eventKey}>
            {HTMLReactParser(item.text, {replace: ReplaceHtml})}
            {
                item.type === 'card_page' && (
                    <div className={'wrapper-tab'}>
                        <Row lg={2} md={1}>
                            {
                                item.child && item.child.map(
                                    (value) => (
                                        <Col
                                            xs={12}
                                            className={'card-col'}
                                            key={value.id}>
                                            <Card>
                                                <LazyLoad
                                                    classNamePrefix={`card-img h-sm-350 h-250 lazyload`}
                                                    placeholder={
                                                        <Skeleton
                                                            className={'card-img h-sm-350 h-250'}/>}>
                                                    {
                                                        value.image ?
                                                            <Card.Img
                                                                className={'h-sm-350 h-250'}
                                                                src={value.image}/> :
                                                            <Skeleton
                                                                className={'card-img h-sm-350 h-250'}/>
                                                    }
                                                </LazyLoad>
                                                <Card.ImgOverlay>
                                                    <Card.Title
                                                        as={'h4'}>
                                                        <Link
                                                            to={value.url}>
                                                            {value.title}
                                                        </Link>
                                                    </Card.Title>
                                                    <div
                                                        className={'card-sub-title'}>
                                                        <Link
                                                            to={value.url}>
                                                            {item.title}
                                                        </Link>
                                                    </div>
                                                    <div
                                                        className={'card-links'}>
                                                        <Link
                                                            to={value.url}>
                                                            <FontAwesomeIcon
                                                                icon="arrow-right"
                                                                height={'10px'}/>
                                                        </Link>
                                                    </div>
                                                </Card.ImgOverlay>
                                            </Card>
                                        </Col>
                                    )
                                )
                            }
                        </Row>
                    </div>
                )
            }
            {
                item.type === 'gallery' && (
                    <div className={'wrapper-tab'}>
                        <Row lg={2} md={1}>
                            {
                                item.photos && item.photos.map(photo => (
                                    <Col
                                        xs={12}
                                        className={'card-col'}
                                        key={photo.id}>
                                        <Card>
                                            <LazyLoad
                                                classNamePrefix={`card-img h-350 lazyload`}
                                                placeholder={
                                                    <Skeleton
                                                        className={'card-img h-350'}/>}>
                                                {
                                                    photo.url ?
                                                        <Card.Img
                                                            className={'h-350'}
                                                            src={photo.url}/> :
                                                        <Skeleton
                                                            className={'card-img h-350'}/>
                                                }
                                            </LazyLoad>
                                        </Card>
                                    </Col>
                                ))
                            }
                        </Row>
                    </div>
                )
            }
        </Tab.Pane>
    );

    if (item.type === 'tree' && item.child && item.child.length > 0) {
        return (
            <Fragment key={item.id}>
                {tabPane(item)}
                {item.child.map(value => <RenderTabContainer item={value} key={value.id}/>)}
            </Fragment>
        );
    }

    return tabPane(item);
};

export default RenderTabContainer;
