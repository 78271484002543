import { useEffect } from 'react';

export function useHideLoader() {
    useEffect(() => {
        const loader = document.querySelector('.loader');
        setTimeout(() => {
            if (loader) {
                loader.classList.add('fadeOut');
                setTimeout(() => loader.remove(), 300);
            }
        }, 300);
    }, []);
}
